import React, { useState, useEffect } from "react"
import { RichText } from "prismic-reactjs"
import { Link, graphql } from "gatsby"
import { Auth, API, graphqlOperation } from "aws-amplify"
import LazyLoad from "react-lazyload"
import {
  renderSEO,
  linkResolver,
  htmlSerializerUpdateStyle,
  Icon,
  ROUTES,
  handleErrorWithPrismic,
  LoadingIndicator,
  LanguageDropdown,
  client,
  isBrowser,
  getLocalStorage,
  CURRENT_LANG,
  DEFAULT_LANGUAGE,
  LANGUAGE_BY_COUNTRY_CODE,
  isEmpty,
  getCurrentUser,
  GetUserSubcomms,
  getParamsFromURL,
} from "@lesmills/gatsby-theme-common"
import { ConfirmSignupUser } from "../graphql/confirmSignupUser"

import type { EmailNewsletterType } from "../types/EmailNewsletterType"

type Props = {|
  pageContext: {
    lang: string,
  },
  data: EmailNewsletterType,
|}

const EmailNewsletterPage = (props: Props) => {
  const { pageContext, data } = props || {}
  const lang = pageContext.unPublishedLang || pageContext.lang
  const [errors, setErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [loggedIn, setLoggedIn] = useState(false)

  if (!data) {
    return null
  }

  const { prismicEmailNewsletter = {} } = data
  const pageContent = prismicEmailNewsletter.data || {}

  const {
    successful_button_label = {},
    successful_description = {},
    successful_title = {},
    error_button_label = {},
    error_description = {},
    error_title = {},
    lmod_ucdsu_err = {},
    invalid_user = {},
    background_image = { url: "", alt: "" },
  } = pageContent

  const getCurrentSession = async (resolve, reject) => {
    client()
    Auth.currentAuthenticatedUser()
      .then(() => {
        setLoggedIn(true)
        resolve()
      })
      .catch(error => {
        reject(error)
      })
  }

  const handleError = (error, defaultError) => {
    handleErrorWithPrismic(error, defaultError, setErrors, data)
  }

  const handleSetUser = async user => {
    // Only verify cioTicket if user hasn't confirmed yet
    if (!user.subCommsConfirmed) {
      try {
        const cioTicket = getParamsFromURL("cioTicket")

        if (!cioTicket) {
          setIsLoading(false)
          setErrors({ message: error_description.text })
          return
        }

        const response = await API.graphql(
          graphqlOperation(ConfirmSignupUser, {
            cioTicket,
          })
        )

        setIsLoading(false)

        // Handle error with prismic data
        if (
          (response.errors && response.errors.length > 0) ||
          !response.data.confirmSignupUser ||
          (!!response.data.confirmSignupUser &&
            !response.data.confirmSignupUser.subCommsConfirmed)
        ) {
          handleError(response.errors || [], lmod_ucdsu_err.text)
        }
      } catch (exceptionError) {
        // Error from api confirm sign up user
        setIsLoading(false)
        handleError(exceptionError, error_description.text)
      }
    } else {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    let defaultBrowserLanguage =
      isBrowser &&
      window.navigator &&
      window.navigator.language &&
      window.navigator.language.toLowerCase()
    defaultBrowserLanguage =
      LANGUAGE_BY_COUNTRY_CODE[defaultBrowserLanguage.split("-")[0]] ||
      DEFAULT_LANGUAGE
    const currentLanguage = getLocalStorage(CURRENT_LANG)

    if (
      lang ===
      (!isEmpty(currentLanguage) ? currentLanguage : defaultBrowserLanguage)
    ) {
      getCurrentSession(
        () => {
          /**
           * Get user to check whether sub was confirmed or not
           * If it has been already confirmed, we don't need to send request again -> SUCCESS screen
           * If it hasn't been confirmed yet, we will send request to verify cioTicket
           */
          getCurrentUser(
            handleSetUser,
            undefined,
            undefined,
            undefined,
            undefined,
            GetUserSubcomms
          )
        },
        exceptionError => {
          // Error from get Auth user
          setIsLoading(false)
          handleError(exceptionError, invalid_user.text)
        }
      )
    }
  }, [])

  return (
    <>
      {renderSEO(pageContent, ROUTES(lang).EMAIL_NEWSLETTER, lang)}
      <div className="w-full flex items-center justify-center h-100vh p-30 md:p-0">
        <div className="w-97/100 flex justify-end absolute top-25 left-20 md:top-60 md:mb-30 mb-150 z-40 pl-22 md:pl-30 pr-40 md:pr-60">
          <LanguageDropdown
            currentLang={lang}
            type="bold"
            isLoggedIn={loggedIn}
          />
        </div>
        <LazyLoad once>
          <img
            className="object-cover h-100vh absolute top-0 left-0 w-full"
            src={background_image.url}
            alt={background_image.alt}
          />
        </LazyLoad>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="bg-modal-950 items-center justify-center text-center flex-col md:w-newsletter-popup md:h-newsletter-popup pt-50 pb-40 pl-30 pr-30 md:pl-150 md:pr-150 z-10">
            <Icon
              icon={errors ? "alert" : "tick-green"}
              type="base"
              classNames={`${
                errors ? "alert-icon-large" : "tick-icon-large"
              } mb-30`}
            />
            <RichText
              render={errors ? error_title.raw : successful_title.raw}
              linkResolver={linkResolver}
              htmlSerializer={htmlSerializerUpdateStyle(
                "",
                "font-base-black text-base md:text-3xl leading-4none md:leading-none text-gray-400 mb-30"
              )}
            />
            <p
              className={`font-base-light md:text-2lg md:leading-7none text-gray-800 leading-2normal text-3xs mb-40`}
            >
              {errors && errors.message
                ? errors.message
                : successful_description.text}
            </p>
            <Link
              className="btn btn-primary uppercase w-full py-3 md:w-btn-md no-underline inline-block leading-2normal"
              to={
                errors
                  ? loggedIn
                    ? ROUTES(lang).CUSTOMER_ACCOUNT
                    : ROUTES(lang).SIGN_IN
                  : ROUTES(lang).CUSTOMER_ACCOUNT
              }
            >
              {(errors ? error_button_label : successful_button_label).text}
            </Link>
          </div>
        )}
      </div>
    </>
  )
}

export const query = graphql`
  query($lang: String) {
    prismicEmailNewsletter(lang: { eq: $lang }) {
      data {
        successful_button_label {
          text
        }
        successful_description {
          text
        }
        successful_title {
          text
          raw
        }
        error_button_label {
          text
        }
        error_description {
          text
        }
        error_title {
          text
          raw
        }
        page_description {
          text
        }
        page_tags {
          text
        }
        page_title {
          text
        }
        page_image {
          url
          alt
        }
        page_author {
          text
        }
        page_og_type {
          text
        }
        page_og_title {
          text
        }
        page_og_description {
          text
        }
        page_twitter_title {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_card {
          text
        }
        background_image {
          alt
          url
        }
        lmod_ucdsu_err {
          text
        }
        invalid_user {
          text
        }
      }
    }
  }
`

export default EmailNewsletterPage
